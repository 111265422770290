<template>
  <!-- 空间角色 -->
  <div class="appContainer spaceBox flex-between">
    <div class="leftBox bb">
      <div class="flex-end header">
        <el-tooltip effect="dark" content="新增" placement="top-start">
          <el-button
            icon="el-icon-plus"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="showAddDialog = true"
          />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新列表" placement="top-start">
          <el-button
            icon="el-icon-refresh"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="refreshListFn"
          />
        </el-tooltip>
      </div>

      <el-scrollbar
        class="list"
        wrap-class="scrollbar-wrapper"
        v-if="rolesList.length"
        v-loading="loadingList"
      >
        <span
          v-for="(item, key) in rolesList"
          class="pointer flex-between text"
          :class="{ active: item.tenantRoleId == form.tenantRoleId }"
          @click="selectRoleFn(item)"
          :key="key"
          ><span class="flex-start">
            <label
              class="circle bb"
              :class="{ red: item.disabled, green: !item.disabled }"
            ></label>
            {{ item.name }}
          </span>
          <el-popconfirm title="确定删除该角色吗？" @confirm="deleRoleFn(item)">
            <el-button
              type="danger"
              size="mini"
              class="deleBtn "
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </span>
      </el-scrollbar>
      <p v-else class="empty bb text-center">暂无数据</p>
    </div>
    <div class="rightBox bb" ref="right_dom" @scroll="handleScroll">
      <div class="box-card" v-if="form.tenantRoleId">
        <h4 class="bb">角色信息</h4>

        <div class="line-box">
          <b class="t">角色名：</b>
          <span
            class=" pointer db text"
            style="min-width:200px;"
            @dblclick="dbItemFn('name')"
          >
            <el-tooltip
              effect="dark"
              content="双击编辑角色名称"
              placement="top-start"
            >
              <div v-show="!isEditName" class="di">
                {{ form.name }}
              </div>
            </el-tooltip>
            <editInput
              ref="editInputChild"
              v-show="isEditName"
              :name="form.name"
              :width="'300px'"
              :placeholder="'请输入角色名称'"
              :word_limit_length="64"
              :inputType="'text'"
              @inputBlur="inputBlurFn($event, 'name')"
            />
          </span>
        </div>
        <div class="line-box">
          <b class="t">描述：</b>
          <span class=" pointer db text" @dblclick="dbItemFn('des')">
            <el-tooltip
              effect="dark"
              content="双击编辑备注"
              placement="top-start"
            >
              <span class="" v-show="!isEditDes">
                <label v-if="form.description != ''">
                  {{ form.description }}</label
                >
                <label v-else> 空</label>
              </span>
            </el-tooltip>
            <editInput
              ref="editDesInputChild"
              v-show="isEditDes"
              :name="form.description"
              :width="'300px'"
              :placeholder="'请输入备注'"
              :word_limit_length="100"
              :inputType="'textarea'"
              @inputBlur="inputBlurFn($event, 'des')"
            />
          </span>
        </div>
        <div class="line-box">
          <b class="t">角色状态：</b
          ><span>
            <el-radio-group v-model="roleStatus" @change="changeRadioFn">
              <el-radio label="0">有效</el-radio>
              <el-radio label="1">失效</el-radio>
            </el-radio-group>
          </span>
        </div>
      </div>
      <div class="box-card" v-if="form.tenantRoleId">
        <h4 class="bb">角色权限</h4>
        <div
          v-if="showFixdTab"
          style="position: sticky;top: -10px;z-index: 4;background-color: #fff;"
        >
          <el-tabs v-model="activeName" @tab-click="tabFn">
            <el-tab-pane
              :label="cell.name"
              :name="cell.name"
              v-for="cell in tabs"
              :key="cell.id"
            />
          </el-tabs>
          <el-table
            style="width:100%;margin-top: -15px;"
            border
            :header-cell-style="{ 'text-align': 'left ' }"
            :cell-style="{ 'text-align': 'left ' }"
            ><el-table-column label="资源编号" /><el-table-column
              label="资源名称"
            /><el-table-column label="资源描述" /><el-table-column
              label="是否授权"
              width="80px"
            />
          </el-table>
        </div>
        <role_limit_table
          ref="rolt_limit_dom"
          :spaceId="spaceId"
          :alreadyAuthorData="alreadyAuthorList"
          @setAuthor="setAuthorFn"
        />
      </div>

      <!-- <template v-if="form.tenantRoleId">
        <el-card class="box-card" v-for="item in cardList" :key="item.id">
          <div slot="header">
            <span class="fW600">{{ item.name }}</span>
          </div>
          <template v-if="item.id == 'base'">
            <div class="line-box">
              <b class="t">角色名：</b>
              <span
                class=" pointer db text"
                style="min-width:200px;"
                @dblclick="dbItemFn('name')"
              >
                <el-tooltip
                  effect="dark"
                  content="双击编辑角色名称"
                  placement="top-start"
                >
                  <div v-show="!isEditName" class="di">
                    {{ form.name }}
                  </div>
                </el-tooltip>
                <editInput
                  ref="editInputChild"
                  v-show="isEditName"
                  :name="form.name"
                  :width="'300px'"
                  :placeholder="'请输入角色名称'"
                  :word_limit_length="64"
                  :inputType="'text'"
                  @inputBlur="inputBlurFn($event, 'name')"
                />
              </span>
            </div>
            <div class="line-box">
              <b class="t">描述：</b>
              <span class=" pointer db text" @dblclick="dbItemFn('des')">
                <el-tooltip
                  effect="dark"
                  content="双击编辑备注"
                  placement="top-start"
                >
                  <span class="" v-show="!isEditDes">
                    <label v-if="form.description != ''">
                      {{ form.description }}</label
                    >
                    <label v-else> 空</label>
                  </span>
                </el-tooltip>
                <editInput
                  ref="editDesInputChild"
                  v-show="isEditDes"
                  :name="form.description"
                  :width="'300px'"
                  :placeholder="'请输入备注'"
                  :word_limit_length="100"
                  :inputType="'textarea'"
                  @inputBlur="inputBlurFn($event, 'des')"
                />
              </span>
            </div>
            <div class="line-box">
              <b class="t">角色状态：</b
              ><span>
                <el-radio-group v-model="roleStatus" @change="changeRadioFn">
                  <el-radio label="0">有效</el-radio>
                  <el-radio label="1">失效</el-radio>
                </el-radio-group>
              </span>
            </div></template
          >
          <template v-if="item.id == 'limit'">
            <role_limit_table
              ref="rolt_limit_dom"
              :spaceId="spaceId"
              :alreadyAuthorData="alreadyAuthorList"
              @setAuthor="setAuthorFn"
            />
          </template> </el-card
      ></template> -->

      <el-empty
        v-if="!form.tenantRoleId"
        style="margin-top:200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>

    <addRole
      :showDialog="showAddDialog"
      @closeDialog="closeAddDialogFn"
      @sureDialog="addSureDialogFn"
    />
    <confirmDialog
      :showConfirmDialog="showConfirmDialog"
      @closeConfirm="closeConfirmFn"
      @sureConfirm="sureConfirmFn"
    >
      <span
        >确认将
        <label class="red">{{ confirmAction.name }} </label> 配置为<label
          class="red"
        >
          {{ confirmAction.action }} </label
        >吗？</span
      >
    </confirmDialog>
  </div>
</template>

<script>
import {
  roles,
  add_edit_role,
  get_role_grant,
  set_role_grant,
  remove_role_grant,
  remove_role,
  if_role_repeat
} from '@/api/space_roles';
export default {
  name: 'roles_list',
  components: {
    confirmDialog: () => import('@/views/components/confirmDialog.vue'),
    addRole: () => import('./components/add_role.vue'),
    editInput: () => import('@/views/components/editInput'),
    role_limit_table: () => import('./components/role_limit_table.vue')
  },
  data() {
    return {
      rolesList: [],
      loadingList: false,
      form: { tenantRoleId: null, disabled: null, name: '', description: '' },
      cardList: [
        { name: '基本信息', id: 'base' },
        // { name: '角色状态', id: 'status' },
        { name: '角色权限', id: 'limit' }
      ],
      roleDetial: {},
      roleStatus: null,
      showConfirmDialog: false,
      confirmAction: {},
      showAddDialog: false,
      isEditName: false,
      isEditDes: false,
      alreadyAuthorList: [], //已经授权的数据
      activeName: '',
      showFixdTab: false,
      tabs: [],
      scrollTop: 0
    };
  },
  mounted() {
    this.spaceId = this.$route.params.spaceId.toString();
    this.get_roles();
  },
  activated() {
    this.showFixdTab = this.scrollTop > 300 ? true : false;
    let dom = document.querySelector('.rightBox');
    dom.scrollTop = this.scrollTop;
  },
  methods: {
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop;
      // console.log(this.scrollTop);
      this.tabs = [...this.$refs.rolt_limit_dom.tabs];
      this.showFixdTab = this.scrollTop >= 380 ? true : false;
      this.activeName = this.$refs.rolt_limit_dom.activeName;
    },
    tabFn() {
      this.$refs.rolt_limit_dom.activeName = this.activeName;
      this.$refs.rolt_limit_dom.tabFn();
    },
    //授权
    setAuthorFn(data) {
      let params = {
        tenantPermissionId: data.tenantPermissionId, //租户权限ID
        tenantRoleId: this.roleDetial.tenantRoleId.toString() //空间角色ID
      };
      //授权
      if (!data.authorOrCancel) {
        set_role_grant(params).then(res => {
          // console.log(res)
          this.$message.success('授权成功');
          this.get_role_grant(); //刷新下授权数据
        });
      } else {
        //取消授权
        remove_role_grant(params).then(res => {
          this.$message.success('取消授权成功');
          this.get_role_grant(); //刷新下授权数据
        });
      }
    },
    inputBlurFn(v, type) {
      this.form.disabled = Number(this.roleStatus);
      if (type == 'name') {
        if (v == '') {
          this.$message.warning('角色名称不能为空');
          return;
        } else {
          this.form.name = v;
        }
        if_role_repeat({
          id: this.roleDetial.tenantRoleId.toString(),
          spaceId: this.spaceId,
          name: 'name',
          value: this.form.name
        }).then(res => {
          if (res) {
            this.$message.warning('角色名称已存在，请重新输入');
          } else {
            this.add_edit_role({ ...this.roleDetial, ...this.form }, () => {
              this.isEditName = false;
              this.isEditDes = false;
              this.$message.success('保存成功');
              this.get_roles();
            });
          }
        });
      } else if (type == 'des') {
        this.form.description = v;
        this.add_edit_role({ ...this.roleDetial, ...this.form }, () => {
          this.isEditName = false;
          this.isEditDes = false;
          this.$message.success('保存成功');
          this.get_roles();
        });
      }
    },
    dbItemFn(type) {
      if (type == 'name') {
        this.isEditName = true;
        this.$nextTick(_ => {
          this.$refs.editInputChild.$refs.nameInput.$refs.input.focus();
        });
      } else if (type == 'des') {
        this.isEditDes = true;
        this.$nextTick(_ => {
          this.$refs.editDesInputChild.$refs.nameInput.$refs.textarea.focus();
        });
      }
    },

    closeAddDialogFn() {
      this.showAddDialog = false;
    },
    addSureDialogFn(v) {
      if_role_repeat({
        id: '',
        name: 'name',
        value: v.name,
        spaceId: this.spaceId
      }).then(res => {
        if (res) {
          this.$message.warning('角色名称已存在，请重新输入');
        } else {
          this.add_edit_role(v, () => {
            this.$message.success('新增成功');
            this.showAddDialog = false;
            this.get_roles();
          });
        }
      });
    },
    closeConfirmFn() {
      this.showConfirmDialog = false;
      let a = this.roleStatus == '1' ? '0' : '1';
      this.$set(this, 'roleStatus', a);
    },
    async add_edit_role(v, cb) {
      const res = await add_edit_role({ ...v, ...{ spaceId: this.spaceId } });
      cb && cb();
    },
    sureConfirmFn() {
      this.form.disabled = Number(this.roleStatus);

      this.add_edit_role(
        { ...this.roleDetial, ...this.form, ...{ spaceId: this.spaceId } },
        () => {
          this.$message.success('保存成功');
          this.showConfirmDialog = false;
          this.get_roles();
        }
      );
    },

    async get_roles(cb) {
      this.loadingList = true;
      try {
        const res = await roles({ spaceId: this.spaceId });
        this.rolesList = res.map(cell => {
          return {
            ...cell,
            tenantRoleId: cell.tenantRoleId.toString(),
            tenantId: cell.tenantId.toString()
          };
        });
        // console.log(this.rolesList, ' this.rolesList')
        cb && cb();
        this.loadingList = false;
      } catch {
        this.loadingList = false;
      }
    },
    changeRadioFn() {
      this.showConfirmDialog = true;
      this.confirmAction.name = this.roleDetial.name + '状态';
      this.confirmAction.action = this.roleStatus == '1' ? '失效' : '有效';
    },
    refreshListFn() {
      let timer = setTimeout(() => {
        this.get_roles(() => {
          this.$message.success('刷新成功');
        });
        clearTimeout(timer);
      }, 200);
    },
    selectRoleFn(item) {
      this.isEditName = false;
      this.isEditDes = false;
      this.form.tenantRoleId = item.tenantRoleId;
      this.form.name = item.name;
      this.form.description = item.description;
      this.roleDetial = item;
      this.roleStatus = this.roleDetial.disabled + '';

      this.get_role_grant();
    },
    //查询角色已经授权
    async get_role_grant() {
      const res = await get_role_grant({
        tenantRoleId: this.roleDetial.tenantRoleId.toString()
      });
      this.alreadyAuthorList = res || [];

      // console.log(this.alreadyAuthorList, '授权数据---------')
    },
    deleRoleFn(row) {
      remove_role({
        tenantRoleId: row.tenantRoleId.toString()
      }).then(res => {
        this.$message.success('删除成功');
        this.get_roles();
        this.roleDetial = null;
        this.form.tenantRoleId = null;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-table__empty-block {
    display: none;
  }
  .el-card__body {
    padding: 10px;
  }
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__header {
    padding: 8px 0;
  }
}

.header {
  height: 40px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.list {
  margin-top: 8px;
  height: calc(100% - 60px);
  .text {
    padding: 5px 10px;
    line-height: 20px;
    margin-bottom: 8px;
    .deleBtn {
      display: none;
      padding: 2px;
    }
    &:hover {
      background: #e8f1ff;
      .deleBtn {
        display: inline-block;
      }
    }
  }
  .active {
    background: #e8f1ff;
    .deleBtn {
      display: inline-block;
    }
  }
  .circle {
    padding: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .green {
    background: #67c23a;
  }
  .red {
    background: red;
  }
}
.box-card {
  h4 {
    background: #f2f2f2;
    padding: 10px;
    border: 1px solid #ebeef5;
  }
  margin-top: 10px;
  .line-box {
    line-height: 32px;
    margin-bottom: 10px;
    .t {
      width: 160px;
      text-align: right;
      display: inline-block;
      margin-right: 5%;
    }
    .text {
      min-width: 200px;
      text-align: left;
      display: inline-block;
    }
  }
}
.save {
  margin: 20px auto;
}
</style>
