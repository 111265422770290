import request from "@/utils/request"

// 获取空间角色
export function roles (params) {
  return request({
    url: `/manage/space/${params.spaceId}/role/findAll`,
    method: "get",
    // params
  })
}
// 创建/编辑空间角色(租户角色)
export function add_edit_role (data) {
  return request({
    url: `/manage/space/${data.spaceId}/role`,
    method: "post",
    data
  })
}
// 查询.空间角色(租户角色).所有授权
export function get_role_grant (data) {
  return request({
    url: `/manage/space/role/${data.tenantRoleId}/grant`,
    method: "get",
    // data
  })
}
// 空间角色(租户角色).授权
export function set_role_grant (params) {
  return request({
    url: `/manage/space/role/grant`,
    method: "post",
    params
  })
}
// 空间角色(租户角色).取消授权
export function remove_role_grant (params) {
  return request({
    url: `/manage/space/role/grant`,
    method: "delete",
    params
  })
}
// 删除空间角色(租户角色)
export function remove_role (data) {
  return request({
    url: `/manage/space/role/${data.tenantRoleId}`,
    method: "delete",
    data
  })
}
// 空间角色验证重复, true: 已存在重复的字段, false: 不存在
export function if_role_repeat (params) {
  return request({
    url: `/manage/space/${params.spaceId}/role/duplicate`,
    method: "get",
    params
  })
}
